import { INSTITUTIONS, WORK_RECORDS_ENABLED_INSTITUTIONS } from '@/common/institutions'
import { getUUIDCookie, setForumCookie, deleteForumCookie, setUUIDCookie } from '@/common/cookie.js'
import { getAccount, deleteAccount } from '../services/account.js'
import { saveFeedback, getFeedbacks } from '../services/user.js'
import {
  startIacSession,
  getExistingIacSession,
  generateSharedShelfCookie,
  logUserIn,
  logUserOut
} from '../services/session.js'
import { findIndividualAccount, checkForumPermissions, findSharedShelfProfile, checkLicense } from '../utils/user.js'
import LoginError from '../utils/authError.js'

export const defaultUser = () => ({
  username: '',
  enabled_for: [],
  profile_id: 0,
  is_artstor_user: false,
  institution_id: 0,
  admin_for: [],
  ga_key: '',
  additional_institutions: [],
  logged_in: false,
  show_controlled_list_tab: false,
  email: '',
  super: false,
  token: '',
  uuid: '',
  can_reset_pw: true,
  can_login: true,
  iac_session_id: '',
  iac_auth_accounts: [],
  iac_auth_profiles: [],
  licensed_products: []
})

const user = {
  namespaced: true,
  state: defaultUser(),
  mutations: {
    SET_USER(state, userObj) {
      Object.assign(state, userObj)
      if (userObj.email === 'qa001@artstor.org') {
        state.super = true
      }
    },
    SET_IAC_SESSION_ID(state, iacSessionId) {
      state.iac_session_id = iacSessionId
    },
    SET_DISABLE_RESET_PW(state, resetValue) {
      state.can_reset_pw = resetValue
    },
    SET_DISABLE_LOGIN(state, loginValue) {
      state.can_login = loginValue
    },
    RESET_STATE(state) {
      Object.assign(state, defaultUser())
    }
  },
  actions: {
    async fetchSession(context) {
      let data = { logged_in: false }
      const uuidCookie = getUUIDCookie()
      data['uuid'] = uuidCookie.length ? uuidCookie : setUUIDCookie()
      const iacResponse = context.state.iac_session_id
        ? await getExistingIacSession(data['uuid'])
        : await startIacSession(data['uuid'])
      data['iac_session_id'] = iacResponse['uuid']
      data['email'] = iacResponse['authenticatedAccounts']?.contact?.email
      data['iac_auth_accounts'] = iacResponse['authenticatedAccounts']
      data['iac_auth_profiles'] = iacResponse['authenticatedProfiles']
      if (iacResponse['licensedProducts'].length) {
        data['licensed_products'] = iacResponse['licensedProducts']
      }

      context.commit('SET_IAC_SESSION_ID', iacResponse['uuid'])
      context.commit('SET_USER', data)
      return data
    },
    async fetchUser(context) {
      const individualUser = findIndividualAccount(context.state.iac_auth_accounts)
      let ssCooke = ''
      let data = { logged_in: false }

      // If there's an individual user in the IAC Session, try to get a sharedshelf cookie
      if (individualUser) {
        ssCooke = await generateSharedShelfCookie()
      } else {
        // Remove any existing sharedshelf cookie if no individual user is found in IAC Session, user has been logged out
        deleteForumCookie()
      }

      // If a sharedshelf cookie is generated, user has permissions to access Forum
      if (ssCooke.length > 0) {
        data.logged_in = true
        data.token = ssCooke

        // Set cookie in the browser for future BE calls
        setForumCookie(ssCooke)

        // TODO: Eventually we want to not get this from FCS but from IAC Service or only use Session data, reducing scope for now
        const accountResponse = await getAccount()

        data = { ...data, ...accountResponse.data }

        context.commit('SET_USER', data)

        if (data.logged_in) {
          await context.dispatch('userSettings/refreshSession', null, { root: true })
        }

        return data.logged_in
      }

      return false
    },
    async logIn(context, options) {
      const loginResponse = await logUserIn(options.email, options.password)

      if (!loginResponse.success) {
        throw new LoginError('Login failed', loginResponse.errorCode)
      }

      // After successful login, we need to fetch the session and fetch the user
      await context.dispatch('fetchSession')
      await context.dispatch('fetchUser')

      const individualUser = findIndividualAccount(context.state.iac_auth_accounts)
      let userHasAccessToForum = false

      if (individualUser) {
        userHasAccessToForum = checkForumPermissions(context.state.iac_auth_profiles)
      }

      if (!userHasAccessToForum) {
        throw new LoginError('No Forum Permissions', 'no_forum_permissions')
      }
    },
    async logOut(context) {
      await Promise.all([logUserOut(), deleteAccount()])
      deleteForumCookie()
      context.commit('RESET_STATE')
      await context.dispatch('fetchSession')
      await context.dispatch('fetchUser')
    },
    async postFeedback(context, options) {
      const { feature, rating, message, role } = options
      const token = context.state.token
      const res = await saveFeedback(token, feature, rating, message, role)
      return res.data
    },
    async fetchFeedback(context, options) {
      const { feature } = options
      const token = context.state.token
      const res = await getFeedbacks(token, feature)
      return res.data
    },
    async setResetDisabled(context, disabledValue) {
      context.commit('SET_DISABLE_RESET_PW', disabledValue)
    },
    async setLoginDisabled(context, disabledValue) {
      context.commit('SET_DISABLE_LOGIN', disabledValue)
    }
  },
  getters: {
    loggedIn: state => state.logged_in,
    userUuid: state => state.uuid,
    hasAdminRights: state => state.admin_for.length > 0,
    isArtstorAdmin: (state, getters) => {
      return getters.isAdminFor(INSTITUTIONS.ARTSTOR)
    },
    isAdminFor: state => institution => {
      return state.admin_for.includes(institution)
    },
    workRecordsEnabled: state => {
      return WORK_RECORDS_ENABLED_INSTITUTIONS.filter(inst => state.institution_id === inst).length > 0
    },
    institutionId: state => state.institution_id,
    token: state => state.token,
    canResetPw: state => state.can_reset_pw,
    canLogin: state => state.can_login,
    institutionUuid: state => {
      const profile = findSharedShelfProfile(state.iac_auth_profiles)
      return profile?.institutionId
    },
    hasAudioLicense: (state, getters, rootState, rootGetters) => {
      return (
        (rootGetters['featureFlags/isFlagEnabled'] &&
          !rootGetters['featureFlags/isFlagEnabled']('enable_av_permission')) ||
        checkLicense(state.licensed_products, 'audio', getters.institutionUuid) ||
        checkLicense(state.licensed_products, 'multimedia', getters.institutionUuid)
      )
    },
    hasVideoLicense: (state, getters, rootState, rootGetters) => {
      return (
        (rootGetters['featureFlags/isFlagEnabled'] &&
          !rootGetters['featureFlags/isFlagEnabled']('enable_av_permission')) ||
        checkLicense(state.licensed_products, 'video', getters.institutionUuid) ||
        checkLicense(state.licensed_products, 'multimedia', getters.institutionUuid)
      )
    },
    hasAudioAndVideoLicense: (state, getters, rootState, rootGetters) => {
      return (
        (rootGetters['featureFlags/isFlagEnabled'] &&
          !rootGetters['featureFlags/isFlagEnabled']('enable_av_permission')) ||
        checkLicense(state.licensed_products, 'multimedia', getters.institutionUuid)
      )
    }
  }
}

export default user
